// 财务显示类型
const stockCtype = {
    INP0: () => { return '采购支出' },
    INP1: () => { return '采购支出(其他)' },
    CINP: () => { return '支出取消' },
    OUT0: () => { return '产品报废' },
    OUT1: () => { return '其他出库' },
    OUT2: () => { return '供应商退款' },
    COUT: () => { return '取消出库' },
    SAOT0A: () => { return '销售收入' },
    SAIN0A: () => { return '退款支出' },
    FA: () => { return '运费支出（未完成订单）' },
    FAC: () => { return '运费支出（已完成订单）' },
    FAG: () => { return '运费支出（退货单）' },
}

const ChangeNoType = {
    OUT2: () => { return 2 },
    SAOT0A: () => { return 1 },
    SAIN0A: () => { return 3 },
    FA: () => { return 1 },
    FAC: () => { return 1 },
    FAG: () => { return 3 },
}

const FinancialTypeClass = {
    // 根据类型获取财务提示
    getStockCtype(type) {
        return stockCtype[type] ? stockCtype[type]() : ''
    },
    //区分变更信息中编号类型  1-销售单编号（跳转） 2-退回单编号（不跳转） 3-退货单编号（跳转）
    getChangeNoType(type) {
        return ChangeNoType[type] ? ChangeNoType[type]() : ''
    }
}
export {
    FinancialTypeClass
}