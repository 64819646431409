<template>
  <div class="FinancialStatistics">
    <el-page-header @back="backPrivious" content="财务统计"></el-page-header>
    <div class="f-w-b">
      <NavigationBar></NavigationBar>
      <div class="main">
        <div class="banner">
          {{ enterpriseStateInfo.enterpriseName }}
        </div>
        <div class="Existing-assets f-c">
          <span>现有资产</span>
          <i @click="btnEye" class="fa fa-eye" aria-hidden="true" v-if="isView"></i>
          <i @click="btnEye" class="fa fa-eye-slash" aria-hidden="true" v-else></i>
        </div>
        <div class="w--100 f-c-b">
          <div class="infoBox" style="background: #E8FDF3;">
            <div class="f-c title">
              <img src="@/assets/supply/property.png" alt="">
              <span>资产（元）</span>
            </div>
            <span class="num">{{ amountObj[0].rmb2 }}</span>
          </div>
          <div class="infoBox" style="background: #E7F8FE;">
            <div class="f-c title">
              <img src="@/assets/supply/collection.png" alt="">
              <span>未收款(元)</span>
            </div>
            <span class="num">{{ amountObj[0].uncollectedRMB2 }}</span>
          </div>
          <div class="infoBox" style="background: #FEF7E5;">
            <div class="f-c title">
              <img src="@/assets/supply/propertyDollar.png" alt="">
              <span>资产(美元)</span>
            </div>
            <span class="num">{{ amountObj[0].usd2 }}</span>
          </div>
          <div class="infoBox" style="background: #FDEAE7;">
            <div class="f-c title">
              <img src="@/assets/supply/uncollected.png" alt="">
              <span>未收款(美元)</span>
            </div>
            <span class="num">{{ amountObj[0].uncollectedUSD2 }}</span>
          </div>
        </div>
        <div class="screen-box f-c-b">
          <div style="color: #333;font-size: 14px;font-weight: 500;">筛选到：<span style="font-size: 24px;color: #0363FA;">{{
            total }}</span> 条相关记录</div>
          <div class="selectionBtn" @click="drawer = true"></div>
        </div>
        <template v-if="StatisticalList && StatisticalList.length">
          <div @click="toFinancialRecordsDetails(item.stockRecordId)" class="item mb-30"
            v-for="(item, index) of StatisticalList" :key="index">
            <div class="w--100 mb-20">
              <div class="title">变更原因：<span>{{ item.stockCtypeText }}</span></div>
            </div>
            <div class="f-c-b">
              <div class="title">创建日期：<span>{{ item.createDate }}</span></div>
              <div class="title">金额变更：<span style="color: #F12828;">{{ item.valuationCurrency }} {{ item.amountChangeType
              }} {{ item.amountChangeNumber2
}}</span>
              </div>
            </div>
          </div>
        </template>
        <!-- <div class="FinancialStatisticsItem" v-if="StatisticalList && StatisticalList.length">
        </div> -->
      </div>
    </div>
    <!-- <div class="Existing-assets f-c" @click="btnEye">
      <span>现有资产</span>
      <i class="fa fa-eye" aria-hidden="true" v-if="isView"></i>
      <i class="fa fa-eye-slash" aria-hidden="true" v-else></i>
    </div>
    <el-table :data="amountObj" border :header-cell-style="{ background: '#4e5a6e', color: '#fff' }">
      <el-table-column label="资产(元)" align="center" prop="rmb2"></el-table-column>
      <el-table-column label="未收款(元)" align="center" prop="uncollectedRMB2"></el-table-column>
      <el-table-column label="资产(美元)" align="center" prop="usd2"></el-table-column>
      <el-table-column label="未收款(美元)" align="center" prop="uncollectedUSD2"></el-table-column>
    </el-table>
    <div class="f-c-b f1 screen">
      <span>筛选到：{{ total }} 条相关记录</span>
      <el-button round plain @click="drawer = true" class=" f-c-c">
        <i class="el-icon-finished"></i>
        <span>筛选</span>
      </el-button>
    </div> -->
    <!-- <div class="FinancialStatisticsItem" v-if="StatisticalList && StatisticalList.length">
      <router-link :to="{
        name: 'FinancialRecordsDetails', 
        query: {
          key: $UrlEncode.encode(JSON.stringify({
            id: item.stockRecordId
          }))
        }
      }" class="f-cl item f1" v-for="(item, index) of StatisticalList" :key="index">
        <div>变更原因：<span>{{ item.stockCtypeText }}</span></div>
        <div>创建日期：<span>{{ item.createDate }}</span></div>
        <div>金额变更：<span>{{ item.valuationCurrency }} {{ item.amountChangeType }} {{ item.amountChangeNumber2 }}</span>
        </div>
      </router-link>
    </div> -->
    <!-- <el-empty v-else description="没有找到订单！"></el-empty> -->
    <Pagination v-if="StatisticalList && StatisticalList.length" :total="total" :pageNum.sync="currentPage"
      :limit.sync="size" :layout="layout" @pagination="fetchData" />
    <el-drawer title="记录筛选" :visible="drawer" :direction="direction" class="drawer-box" :before-close="handleClose">
      <el-form ref="drawerForm" :model="drawerForm" class="drawer-form">
        <el-form-item label="资产变化：" prop="amountChangeType">
          <SelectOption :selectModel="drawerForm.amountChangeType" :Option="AssetChangesOptions"
            :type="'amountChangeType'" @selectAmountChangeType="selectAmountChangeType">
          </SelectOption>
        </el-form-item>
        <el-form-item label="变更原因：" prop="stockCtype">
          <SelectOption :selectModel="drawerForm.stockCtype" :disabled="stockCtypeDisable" :Option="ChangeReasonOptions"
            :type="'stockCtype'" @selectStockCtype="selectStockCtype">
          </SelectOption>
        </el-form-item>
        <el-form-item label="修改时间："></el-form-item>
        <el-form-item prop="searchBeforeDate">
          <el-date-picker v-model="drawerForm.searchBeforeDate" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择起始时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="searchAfterDate">
          <el-date-picker v-model="drawerForm.searchAfterDate" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择结束时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item class="btns">
          <el-button type="primary" @click="submitFilter">提交</el-button>
          <el-button @click="resetForm('drawerForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>

<script>
import { PaginationRetention } from '@/mixins/PaginationRetentionMixin';
import { AssetChangesOptions, ChangeReasonOptionsAdd, ChangeReasonOptionsReduce } from '@/utils/SelectOption';
import { FinancialTypeClass } from '@/utils/FinancialTypeClass'
export default {
  name: 'FinancialStatistics',
  components: {
    NavigationBar: () => import(/* webpackChunkName: "UploadVideo" */ '../components/NavigationBar.vue')
  },
  mixins: [PaginationRetention],
  data() {
    return {
      isView: false,//是否查看
      amountObj: [],
      amountObjView: [],
      amountObjNoView: [{
        rmb2: '******',
        uncollectedRMB2: '******',
        usd2: '******',
        uncollectedUSD2: '******'
      }],
      size: 20,
      drawer: false,
      direction: 'rtl',
      AssetChangesOptions,
      ChangeReasonOptions: [],
      drawerForm: {
        amountChangeType: '',//搜索条件金额变更类型(+,-)
        stockCtype: '',//搜索条件库存变更类型:参数如下(INP0, INP1, COUT, OUT0, OUT1, CINP, SAOT0A, SAIN0A, FA,FAC,FAG)
        searchBeforeDate: '',//起始时间
        searchAfterDate: '',//结束时间
      },
      StatisticalList: [],//统计列表
      stockCtypeDisable: true,//变更原因默认禁用
      enterpriseStateInfo: {},//权限
    }
  },
  created() {
    this.enterpriseStateInfo = this.$getStorage('enterpriseStateInfo')
    this.amountTotal()
    let FinancialStatisticsDrawerForm = this.$getStorage('FinancialStatisticsDrawerForm') ? this.$getStorage('FinancialStatisticsDrawerForm') : '';
    if (FinancialStatisticsDrawerForm) {
      let { amountChangeType } = FinancialStatisticsDrawerForm;
      if (amountChangeType == '+') {
        this.stockCtypeDisable = false
        this.ChangeReasonOptions = ChangeReasonOptionsAdd
      } else if (amountChangeType == '-') {
        this.stockCtypeDisable = false
        this.ChangeReasonOptions = ChangeReasonOptionsReduce
      } else {
        this.stockCtypeDisable = true
      }
    }
  },
  methods: {
    btnEye() {
      this.isView = !this.isView;
      if (this.isView) {
        this.amountObj = this.amountObjView;
      } else {
        this.amountObj = this.amountObjNoView;
      }
    },
    // 财务统计接口
    amountTotal() {
      let that = this;
      that.$http.amountTotal({
        userId: parseInt(that.$store.state.userId),
        token: that.$getStorage('token')
      }).then(res => {
        let { rmb2, uncollectedRMB2, usd2, uncollectedUSD2 } = res.data;
        this.amountObj = this.amountObjNoView
        this.amountObjView = [{
          rmb2,
          uncollectedRMB2,
          usd2,
          uncollectedUSD2
        }];
      })
    },
    // 分页切换
    getDate() {
      this.$getStorage('FinancialStatisticsDrawerForm') ? this.drawerForm = this.$getStorage('FinancialStatisticsDrawerForm') : ''
      this.productStockAmountList(this.paging, this.pagingSize)
    },
    // 产品库存单据财务数据列表接口
    productStockAmountList(page = this.page, size = this.size) {
      let that = this,
        { amountChangeType, stockCtype, searchBeforeDate, searchAfterDate } = that.drawerForm
      that.$http.productStockAmountList({
        changeUserId: parseInt(that.$store.state.userId),
        token: that.$getStorage('token'),
        page2: page,
        size,
        amountChangeType,
        stockCtype,
        searchBeforeDate,
        searchAfterDate
      }).then(res => {
        if (res.code == 200) {
          res.data.records.forEach(item => {
            item.stockCtypeText = FinancialTypeClass.getStockCtype(item.stockCtype)
          })
          that.StatisticalList = res.data.records
          that.total = res.data.total
          that.currentPage = res.data.current
        }
      })
    },
    // 资产变化
    selectAmountChangeType(value) {
      if (value == '+') {
        this.stockCtypeDisable = false;
        this.ChangeReasonOptions = ChangeReasonOptionsAdd;
      } else if (value == '-') {
        this.stockCtypeDisable = false;
        this.ChangeReasonOptions = ChangeReasonOptionsReduce
      } else {
        this.stockCtypeDisable = true;
        this.ChangeReasonOptions = [];
      }
      this.drawerForm.amountChangeType = value
      this.drawerForm.stockCtype = ''
    },
    // 变更原因
    selectStockCtype(value) {
      this.drawerForm.stockCtype = value
    },
    // 关闭记录筛选
    handleClose() {
      this.drawer = false
    },
    submitFilter() {
      this.$setStorage('FinancialStatisticsDrawerForm', this.drawerForm)
      this.productStockAmountList();
      this.drawer = false
    },
    // 重置记录筛选
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    toFinancialRecordsDetails(id) {
      this.$router.push({
        name: 'FinancialRecordsDetails',
        query: {
          key: this.$UrlEncode.encode(JSON.stringify({
            id: id
          }))
        }
      })
    }
  },
}

</script>
<style lang='less' scoped>
@import '@/assets/css/JumpListItem.less';

.FinancialStatistics {
  .main {
    width: 980px;

    .banner {
      background: url('../../../assets/supply/supplyBanner.png');
      height: 100px;
      color: #FFFFFF;
      font-size: 30px;
      line-height: 100px;
      text-align: center;
      margin-bottom: 40px;
    }
  }


  .Existing-assets {
    margin: 25px 0 15px;

    span {
      color: #333;
      font-size: 20px;
      margin-right: 20px;
      font-weight: bold;
    }

    i {
      color: #333;
      font-size: 20px;
    }
  }

  .Existing-assets:hover {
    cursor: pointer;
  }

  .infoBox {
    width: 180px;
    height: 100px;
    border-radius: 10px;
    padding: 30px;

    .title {
      span {
        font-size: 16px;
        color: #333;
        font-weight: 400;
      }
    }

    .num {
      font-size: 26px;
      font-family: Montserrat;
      word-wrap: break-word;
    }
  }


  .screen-box {
    margin: 20px 0;

    .selectionBtn {
      width: 120px;
      height: 60px;
      background: url('../../../assets/supply/screen.png');
      cursor: pointer;
      margin: 0 25px;
    }
  }

  .item {
    padding: 40px 60px;
    background: #F8F8F8;
    border-radius: 10px;
    cursor: pointer;

    .title {
      color: #666;
      font-weight: 400;
      font-size: 16px;

      span {
        color: #333333;
        font-weight: 400;
        font-size: 16px;
      }
    }
  }

  .drawer-box {
    .drawer-form {
      padding: 10px 25px;

      .el-input {
        margin-bottom: 10px;
      }

      .el-date-editor.el-input {
        width: 100% !important;
      }

      .btns {
        /deep/ .el-form-item__content {
          display: flex;
          align-items: center;
          justify-content: space-around;

          .el-button {
            width: 30%;
          }
        }
      }
    }
  }
}
</style>
